<template>
  <svg
    :width="width"
    viewBox="0 0 86 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M79.3297 8.90625C78.9227 9.07823 78.4992 9.15478 78.0854 9.14583C78.0962 9.14583 78.1079 9.14583 78.1186 9.14484C78.4836 9.1349 78.8525 9.05736 79.2106 8.90625C80.7847 8.24218 81.5322 6.40406 80.8803 4.80154C80.4002 3.62152 79.2955 2.89681 78.1167 2.86301C78.103 2.86202 78.0884 2.86202 78.0737 2.86202C79.3121 2.8312 80.4978 3.56684 80.9994 4.80154C81.6513 6.40406 80.9038 8.24218 79.3297 8.90625Z"
      fill="#27DDBB"
    ></path>
    <path
      d="M21.8156 0.307129L18.8958 8.46585L15.9994 0.307129H12.7214L17.1412 11.6997H20.6153L24.9901 0.307129H21.8156Z"
      fill="#27DDBB"
    ></path>
    <path
      d="M43.6595 0C42.3899 0 41.1652 0.435423 40.3699 1.64626V0.306188H37.4384V11.6998H40.4489V5.45472C40.4489 3.73888 41.5224 2.88195 42.8154 2.88195C44.1875 2.88195 44.9965 3.78659 44.9965 5.43285V11.6988H48.009V4.4447C48.01 1.79935 46.1988 0 43.6595 0Z"
      fill="#27DDBB"
    ></path>
    <path
      d="M69.5827 0.307129L66.6629 8.46585L63.7665 0.307129H60.4885L64.9083 11.6997H68.3824L72.7572 0.307129H69.5827Z"
      fill="#27DDBB"
    ></path>
    <path
      d="M30.4326 0.00500488C27.181 0.00500488 24.5452 2.69012 24.5452 6.00252C24.5452 9.31492 27.181 12 30.4326 12C33.6842 12 36.3191 9.31492 36.3191 6.00252C36.3191 2.69012 33.6842 0.00500488 30.4326 0.00500488ZM30.4326 9.14492C28.7297 9.14492 27.3479 7.73725 27.3479 6.00252C27.3479 4.26778 28.7297 2.86011 30.4326 2.86011C32.1355 2.86011 33.5164 4.26778 33.5164 6.00252C33.5164 7.73725 32.1355 9.14492 30.4326 9.14492Z"
      fill="#27DDBB"
    ></path>
    <path
      d="M55.0344 0.00500488C51.7828 0.00500488 49.1479 2.69012 49.1479 6.00252C49.1479 9.31492 51.7828 12 55.0344 12C58.286 12 60.9219 9.31492 60.9219 6.00252C60.9219 2.69012 58.285 0.00500488 55.0344 0.00500488ZM55.0344 9.14492C53.3315 9.14492 51.9507 7.73725 51.9507 6.00252C51.9507 4.26778 53.3315 2.86011 55.0344 2.86011C56.7383 2.86011 58.1191 4.26778 58.1191 6.00252C58.1191 7.73725 56.7383 9.14492 55.0344 9.14492Z"
      fill="#27DDBB"
    ></path>
    <path
      d="M8.76139 0.305194V1.56375C8.02168 0.635241 6.94822 0 5.41414 0C2.33136 0 0 2.57576 0 5.99751C0 9.41827 2.33136 11.994 5.41219 11.993C6.94822 11.993 8.03241 11.3578 8.75944 10.4402V11.6988H11.7036V0.305194H8.76139ZM6.85648 8.9063C6.49834 9.05741 6.12946 9.13495 5.76448 9.14489C4.5222 9.18167 3.33065 8.44602 2.8271 7.20636C2.17522 5.60285 2.92176 3.76472 4.49585 3.10065C4.91059 2.92569 5.34193 2.85014 5.76253 2.86306C6.94139 2.89686 8.04607 3.62157 8.5262 4.80159C9.17809 6.40411 8.43057 8.24223 6.85648 8.9063Z"
      fill="#27DDBB"
    ></path>
    <path
      d="M81.1155 0.305194V1.56375C80.3758 0.635241 79.3023 0 77.7683 0C74.6855 0 72.3541 2.57576 72.3541 5.99751C72.3541 9.41827 74.6855 11.994 77.7663 11.993C79.3023 11.993 80.3865 11.3578 81.1136 10.4402V11.6988H84.0578V0.305194H81.1155ZM78.0688 9.14588C76.8441 9.16179 75.678 8.42912 75.1812 7.20636C74.5293 5.60285 75.2759 3.76472 76.85 3.10065C77.2472 2.93265 77.659 2.85709 78.063 2.86207H78.0737C78.0884 2.86207 78.103 2.86207 78.1167 2.86306C79.2955 2.89686 80.4002 3.62157 80.8803 4.80159C81.5322 6.40411 80.7847 8.24223 79.2106 8.9063C78.8525 9.05741 78.4836 9.13495 78.1186 9.14489C78.1079 9.14588 78.0962 9.14588 78.0854 9.14588H78.0688Z"
      fill="#27DDBB"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "Logo",
  props: ["width"],
};
</script>
